<template>
	<div class="page"> 

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="68px">
			<el-form-item label-width="0">
				<el-button type="primary" style="background-color:#795A25;border: none;" @click="page_ser">录入</el-button>
				<el-button type="primary" style="background-color:#FCF8ED;border: 1px solid #795A25;color:#795A25;" @click="batch_edit(0)">提交发货</el-button>
				<el-button type="success" @click="download_csv">下载模板</el-button>
			</el-form-item>
		</el-form>
		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="tord" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading" ref='multipleTable'>
				<el-table-column label="失败原因" width="180px">
                     <template slot-scope="scope">
						 <div class="tab_line_item" style="color:red">{{scope.row.error_info}}</div>
					</template>
				</el-table-column>
				<el-table-column label="运单编号" width="200px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.truck_tord_num}}</div>
					</template>
				</el-table-column>
                <el-table-column label="线路编号" width="200px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.bl_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="装卸货地信息"  width="200px">
					<template slot-scope="scope">
						<div class="tab_line_item">发货地:{{scope.row.case_addr}}</div>
						<div class="tab_line_item">卸货地:{{scope.row.aim_addr}}</div>
					</template>
				</el-table-column>
				<el-table-column label="司机信息" width="180px">
					<template slot-scope="scope">
						<div class="tab_line_item">司机姓名:{{scope.row.driver_name}}</div>
						<div class="tab_line_item">司机电话:{{scope.row.driver_tel}}</div>
					</template>
				</el-table-column>
				<el-table-column label="车牌号" width="180px">
                    <template slot-scope="scope">
						 <div class="tab_line_item">{{scope.row.truck_plate_num}}</div>
					</template>
                   
				</el-table-column>
                <el-table-column label="货物名称" width="180px">
                    <template slot-scope="scope">
						 <div class="tab_line_item">{{scope.row.cargo_name}}</div>
					</template>
				</el-table-column>
                <el-table-column label="货物重量" width="180px">
                    <template slot-scope="scope">
						 <div class="tab_line_item">{{scope.row.cargo_weight}}</div>
					</template>
				</el-table-column>
                <el-table-column label="货物体积" width="180px">
                     <template slot-scope="scope">
						 <div class="tab_line_item">{{scope.row.cargo_volume}}</div>
					</template>
				</el-table-column>
                <el-table-column label="运费" width="180px">
                     <template slot-scope="scope">
						 <div class="tab_line_item">{{scope.row.freight_total}}</div>
					</template>
				</el-table-column>
			</el-table> 
		 </div>
	    <!-- 回到顶部 -->
        <el-backtop target=".page" :bottom="70" :right="120"></el-backtop>
		<!-- 分页 -->
		<!-- <el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 50, 100, 200]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination> -->


	</div>
</template>

<script>
	import { Loading } from 'element-ui';
	export default {
		components:{
		},
		data() {
			return {

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
				tord:[],
                tord_fail:[],//发货失败得
			}
		},
		created() {

		},
		methods: {
			download_csv(){
				//生成下载链接
				let link = document.createElement('a');
				//测试服 https://zwbqytest.jungong56.com/file/批量发货模板.xlsx
				//正式服 https://zwbqy.jungong56.com/file/批量发货模板.xlsx
				link.href = 'https://zwbqytest.jungong56.com/file/批量发货模板.xlsx';

				//对下载的文件命名
				link.download = "批量发货模板.xlsx";

				//下载
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				
				// this.truck_tord_nums=[];
			},
			//批量修改提交
			batch_edit(n){
                //先清空失败得数据
                if(n==0){
                    this.tord_fail = []
                }
                
                let item = this.tord[n]
                //遍历完毕 置入发货失败得数据
                if(!item){
                    if(this.tord_fail.length >=0){
                        this.tord = this.tord_fail
                    }
                    
                    //关闭特效
                    let loadingInstance = Loading.service(this.options);
                    this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                        loadingInstance.close();
                    });
                    //提示
                    this.$my.other.msg({
                        type:'success',
                        str:'批量发货完毕'
                    })
                    return
                }
                //遍历
               this.$my.net.req({
                   take_over_control:1,
                   data:{
                        mod:'truck_tord_real_time',
                        ctr:'excel_fahuo',
                        truck_tord_num:item.truck_tord_num,
                        bl_num:item.bl_num,
                        driver_name:item.driver_name,
                        driver_tel:item.driver_tel,
                        truck_plate_num:item.truck_plate_num,
                        cargo_name:item.cargo_name,
                        cargo_weight:item.cargo_weight,
                        cargo_volume:item.cargo_volume,
                        freight_total:item.freight_total,
                        case_addr:item.case_addr,
                        aim_addr:item.aim_addr,
                   },callback:(data)=>{
                       if(data.code !=0){
                           n++
                           item.error_info = data.error_info
                           this.tord_fail.push(item)
                           this.batch_edit(n)
                       }else{
                           n++
                           this.batch_edit(n)
                       }
                   }
                })
			},
			//录入功能
            page_ser(){
                this.$my.excle.importExcel((data1, dataRef) => {
					var data = []
					//去除空数据
					data1.forEach((item=>{
						if(item.length >0){
							data.push(item)
						}
					}))
					if(data.length>1000){
						this.$my.other.msg({
							type:'info',
							str:'最大录入量为1000条,请修改后重新录入'
					    })
						return
					}
					var tels = []
					var tord_list = []
					//获取对应位置下标
					var truck_tord_num_index = data[0].indexOf('运单编号')
                    var bl_num_index = data[0].indexOf('线路编号（选填）')
					var driver_info_tel_index = data[0].indexOf('司机手机号')
                    var driver_info_name_index = data[0].indexOf('司机姓名')
					var excle_freight_total_index = data[0].indexOf('运费')
					var truck_plate_num_index = data[0].indexOf('车牌号码')

                    var cargo_name_index = data[0].indexOf('货物名称')
					var cargo_weight_index = data[0].indexOf('货物重量')
					var cargo_volume_index = data[0].indexOf('货物体积')

					var case_addr_index = data[0].indexOf('完整发货地址')
                    var aim_addr_index = data[0].indexOf('完整到货地址')

					//删除表头
					delete data[0]
					data.forEach((item,index)=> {
						tord_list.push({
							truck_tord_num:item[truck_tord_num_index]?item[truck_tord_num_index]:'无',
							
                            bl_num:item[bl_num_index]?item[bl_num_index]:'无',
                            driver_tel:item[driver_info_tel_index],
                            driver_name:item[driver_info_name_index],
                            freight_total:item[excle_freight_total_index],
                            truck_plate_num:item[truck_plate_num_index],
                            cargo_name:item[cargo_name_index],
                            cargo_weight:item[cargo_weight_index],
                            cargo_volume:item[cargo_volume_index],
                            case_addr:item[case_addr_index]?item[case_addr_index]:'无',
                             aim_addr:item[aim_addr_index],
						})
					});
                    this.list.total =tord_list.length
                    this.tord = tord_list
                    console.log(this.tord)
                });
            },
			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
				
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: 100%;
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height: 700px;
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>